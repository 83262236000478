// import React, {useState , useRef, useEffect} from 'react'
// import GlobalUserState from "./contexts/GlobalUserState";
import Routes from "./Routes";
//import { Button } from "@mui/material";

function App() {
  // const history = React.useHistory();

  return (
    // <GlobalUserState.Provider value={[state, setState]}>
    <Routes />
    // </GlobalUserState.Provider>
  );
}

// const Home = () => {
//   return (
//     <div>
//       <h1>Home Page</h1>
//     </div>
//   );
// };

export default App;
